import React from 'react';

function MadiAdmin() {
	return (
		<div>
			page admin
		</div>
	);
}

export default MadiAdmin;
