import React from 'react';
import {css} from '@emotion/react';
import {ClipLoader, PacmanLoader} from 'react-spinners';

const styleSpinner = css`
	display:block;
	margin: auto;
`;

function Loading() {
	return (
		<div className="w-full flex items-center justify-center w-100" id="page-loading">
			<PacmanLoader color={'#4ed515'} css={styleSpinner} size={25} />
		</div>
	);
}

export default Loading;
