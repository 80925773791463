import React, {useState} from 'react';

function MenuDeroulant({children}) {
	const [oIndex, setOindex] = useState(null);

	const handleToggle = (index) => {
		setOindex(oIndex === index ? null : index);
	};

	return (
		<div>
			{React.Children.map(children, (child, index) => 
				React.cloneElement(child, {menuOuvert: index === oIndex,
					onToggle: () => handleToggle(index),
				})
			)}
		</div>
	);
}

function SectionMenu({titre, children, menuOuvert, onToggle}) {
	return (
		<div className="accordion-i">
			<div className="accordion-titre" onClick={onToggle}>
				{titre}
				<span className={`accordion-label ${menuOuvert ? 'open' : ''}`}>+</span>
			</div>
			{menuOuvert && <div className="accordion-div">{children} </div>}
		</div>
	);
}


function MadiTabDemande() {
	return (
		<div className="w-full">
			Liste du menu disponible 
			<MenuDeroulant className="w-full">
				<SectionMenu className="w-full" titre="Repas">
					<p> les repas disponible </p>
				</SectionMenu>
				<SectionMenu titre="Accompagnement">
					<p> les accompagnements disponible </p>
				</SectionMenu>
				<SectionMenu titre="Boisson">
					<p> les boissons disponible </p>
				</SectionMenu>
			</MenuDeroulant>
		</div>
	);
}

export default MadiTabDemande;
