import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Routes, Route, Link, Switch} from 'react-router-dom';
import './App.css';

import MadiAccueil from './MadiAccueil';
import MadiAdmin from './MadiAdmin';
import Loading from './Loading';

function IndexRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/admin" element={<MadiAdmin />} />
        <Route path="/" element={<MadiAccueil />} />
      </Routes>
    </Router>
  );
}

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 4500)
    return () => clearTimeout(timer)
  }, []);

  return (
    <div>
      {loading ? <Loading /> : <IndexRoutes />}
    </div>
  );
}

export default App;
