import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Webcam from 'react-webcam';
import axios from 'axios';

import TabTitre from './TabTitre';
import MadiTabDemande from './MadiTabDemande';
import MadiTabAttente from './MadiTabAttente';

import './Tabstyle.css';


function MadiAccueil() {
	const [activeTab, setActivetab] = useState('demande');
	const [dataSrc, setDatasrc] = useState('');
	const webcamRef = useRef(null);

	const capture = useCallback(async () => {
		const imageSrc = webcamRef.current.getScreenshot();
		setDatasrc(imageSrc);
	}, [webcamRef]);

	const renderContent = () => {
		switch (activeTab) {
			case 'demande':
				return <MadiTabDemande />;
			case 'attente':
				return <MadiTabAttente />;
			default:
				return null;
		}
	};
	
	return (
		<div className="w-full text-center" id="page-madi-accueil">
			<div className="w-100 flex flex-wrap items-center justify-center bg-gray-200 m-3 p-2 rounded-md shadow-md">
				<img className="w-1/3" src="logo_madi.png" alt="madi" />
				<p className="w-2/3 text-2xl">
					Mutuelle des Agents de l'Informatique
				</p>
			</div>
			<hr/>
			<div className="w-full flex bg-white my-6 px-3 flex-col">
				<div className="w-full flex flex-wrap justify-between mb-4">
					<div className="w-full tab-group flex flex-wrap justify-between bg-gray-50">
						<TabTitre label="Demander" activeTab={activeTab} setActivetab={setActivetab} tabKey="demande" />
						<TabTitre label="En attente" activeTab={activeTab} setActivetab={setActivetab} tabKey="attente" />
					</div>
				</div>
				<div className="w-full flex flex-wrap">
					<div className="w-full">
						{renderContent()}
					</div>
				</div>				
			</div>
			<hr/>
			<p>
				{dataSrc}
			</p>
			<>
				<Webcam
					audio={false}
					ref={webcamRef}
					screenshotFormat="image/jpeg"
					width="100%"
					height="auto"
				/>
				<button className="bg-red-100 px-2" onClick={capture}>
					camera
				</button>
			</>
		</div>
	);
}

export default MadiAccueil;