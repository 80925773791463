import React from 'react';

function TabTitre ({label, activeTab, setActivetab, tabKey}) {
	
	const handleClick = () => {
		setActivetab(tabKey);
	};

	return (
		<button className={`tab-element ${activeTab === tabKey ? 'tab-element-encours' : ''}`} onClick={handleClick}>
			{label}
		</button>
	);
}

export default TabTitre;
