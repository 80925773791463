import React from 'react';

function MadiTabAttente() {
	return (
		<div>
			Demande tattente
		</div>
	);
}

export default MadiTabAttente;
